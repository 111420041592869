import React, { useState , useEffect, useContext, useRef} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/dashboard.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift,
    faRefresh,
    faCopy} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GExpired from '../components/gExpired';

/* import { auth, db } from "../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore"; */


function Dashboard() {
    const navigate = useNavigate();
    const { profile, token, setProfile, setActivePair, setActiveLevel,setToken } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [user, setUser] =useState({});
    const [loading, setLoading] =useState(false);
    const [game, setGame]=useState(null);
    const [level, setLevel] =useState(null);
    const [interest, setInterest]=useState(null);
    const [pair, setPair] =useState(null);
    const [balance, setBalance]=useState(0);
    const [isInterested, setIsInterested]=useState(false);
    const [isJoin, setIsJoin]=useState(false); //this is for when interest is completed
    const [errmsg, setErrmsg]=useState('');
    const [isExpired, setIsExpired] =useState(false);
    const [iCopy, setICopy] =useState(false);
    const [seconds, setSeconds] =useState(0);
    const [gameEnded, setGameEnded] =useState(false);
    const [winAmount, setWinAmount] =useState(0);

    const count=useRef();
    const timoutcall =useRef(null);
    const levelstatus=useRef({});
    const stateid =useRef(-2);
    const interestid =useRef(0);
    const levelid =useRef(0);
    const gameid =useRef(0);
    const statetimeout=useRef(null);

    const stopSec=1;

    //var timoutcall=null;

    //console.log(profile);
    useEffect(() => {
     
      if(profile==null){
        var str_p=localStorage.getItem("profile" )
       var str_token= localStorage.getItem("token");
        var s_profile=JSON.parse(str_p);
        setProfile(s_profile);
        setToken(str_token);
        Load2(str_token);
        //fetchData2(path)
      }else{
        Load();
       // fetchData();
      }
      if(statetimeout.current!=null){
        clearTimeout(statetimeout.current);
      }
      
       //LoadReports();
            

            return () => {

              console.log(`on beforeClear timeout id:******** ${statetimeout.current}`)
              clearTimeout(timoutcall.current);
              timoutcall.current=null;
              clearTimeout(statetimeout.current);
              statetimeout.current=null;
              console.log('clearing out the timer because page change');
          };

         }, []);

      /*    const fetchData = async () => {
          try {
            const querySnapshot = await getDocs(collection(db, "your-collection-name"));
            querySnapshot.forEach((doc) => {
              console.log(`${doc.id} =>`, doc.data());
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };

        const fetchData2 = async (pt) => {
          try {
            const querySnapshot = await getDocs(collection(db, "your-collection-name"));
            querySnapshot.forEach((doc) => {
              console.log(`${doc.id} =>`, doc.data());
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
       */
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/game/dashboard`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
                setGame(data.game); setLevel(data.level); setInterest(data.gameInterest); setPair(data.gamePair); setBalance(data.currentbal);
                setActivePair(data.gamePair);
                
                setActiveLevel(data.level);
                setSeconds(data.seconds);
                
                count.current=data.seconds;
                console.log('this timeout call exist. so we disable it'+timoutcall.current)
                if(timoutcall.current!=null){
                  //cancel timeout
                  console.log('this timeout call exist. so we disable it'+timoutcall.current)
                  clearTimeout(timoutcall.current);
                }

                localStorage.setItem("activepair", JSON.stringify(data.gamePair));
                localStorage.setItem("level", JSON.stringify(data.level));

                levelstatus.current={status:data.level?.status,id:data.level?.id}
                
                if(data.level?.status=='active'){
                  console.log('call refresh when the time reached');
                  onStartTime();
                }
                

                //let fill out the stateid
                interestid.current=data.gameInterest==null?0: data.gameInterest?.id;
                levelid.current=data.level==null?0:data.level.id;

                if(gameid.current!=0 && data.game==null){
                  // the game ended, so we waiting another.
                  //load the winning if any 
                  GameEndStatus(gameid.current);
                  setGameEnded(true);

                }
                gameid.current=data.game==null?0:data.game.id;
                if(data.game==null){
                  
                  stateid.current=0;
                }
                else if(data.game!=null && data.level==null){
                  stateid.current=1;
                }
                else if(data.game!=null && data.level!=null && data.level.status=='active' && data.gameInterest==null){
                  stateid.current=2;
                }
                else if(data.game!=null && data.level!=null && data.level.status=='ongoing' && data.gameInterest==null){
                  stateid.current=21;
                }
                else if(data.game!=null && data.level!=null && data.level.status=='completed' && data.gameInterest==null){
                  stateid.current=22;
                }

                else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='ready'){
                  stateid.current=3;
                }
                else if(data.game!=null && data.level!=null && data.gameInterest!=null && (data.gameInterest.status!='lost' || data.gameInterest.status!='expired' || data.gameInterest.status!='timeout')){
                  stateid.current=31;
                }
               /*  else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='ready'){
                  stateid.current=32;
                } */

                    else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='pair' && data.level.status=='active' ){
                      stateid.current=41;
                    }
                    else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='pair' && data.level.status=='ongoing' ){
                      stateid.current=42;
                    }
                    else if(data.game!=null && data.level!=null && data.gameInterest!=null  && data.level.status=='completed' ){
                      stateid.current=43;
                    }
                    else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='ready' && data.level.status=='ongoing' ){
                      stateid.current=44;
                    }
                else if(data.game!=null &&  data.gameInterest!=null && (data.gameInterest.status=='timeout' || data.gameInterest.status=='expired')){
                  stateid.current=5;
                }
                else if(data.game!=null && data.level==null && data.gameInterest!=null && data.gameInterest.status=='ready'){
                  stateid.current=6;
                }
                console.log(`state status ${stateid.current}`)
                if (statetimeout.current) {
                  clearTimeout(statetimeout.current);
              }
                statetimeout.current= setTimeout(CheckState,10000);
                console.log(`on load timeout id:******** ${statetimeout.current}`)
            }else if(data.code==-10){
              setIsExpired(true);
            }
            else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    

     const Load2=async(s_token )=>{
      
      var urls=`${Constants.rooturl}/game/dashboard`;
      try {
          setLoading(true);
          const resp = await fetch(urls, { 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': s_token,
              
            },
          });
          
          const data = await resp.json();
        
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              setGame(data.game); setLevel(data.level); setInterest(data.gameInterest); setPair(data.gamePair); setBalance(data.currentbal);
              setActivePair(data.gamePair);
              setActiveLevel(data.level);
              setSeconds(data.seconds);
              console.log('this timeout call exist. so we disable it'+timoutcall.current)
              if(timoutcall.current!=null){
                //cancel timeout
                console.log('this timeout call exist. so we disable it '+timoutcall.current)
                clearTimeout(timoutcall.current);
              }
              localStorage.setItem("activepair", JSON.stringify(data.gamePair));
                localStorage.setItem("level", JSON.stringify(data.level));

                levelstatus.current={status:data.level?.status,id:data.level?.id}
                if(data.level?.status=='active'){
                  onStartTime();
                }

                //let fill out the stateid
                interestid.current=data.gameInterest==null?0: data.gameInterest?.id;
                levelid.current=data.level==null?0:data.level.id;

                if(gameid.current!=0 && data.game==null){
                  // the game ended, so we waiting another.
                  //load the winning if any 
                  GameEndStatus(gameid.current);
                  setGameEnded(true);

                }
                gameid.current=data.game==null?0:data.game.id;
                if(data.game==null){
                  
                  stateid.current=0;
                }
                else if(data.game!=null && data.level==null){
                  stateid.current=1;
                }
                else if(data.game!=null && data.level!=null && data.level.status=='active' && data.gameInterest==null){
                  stateid.current=2;
                }
                else if(data.game!=null && data.level!=null && data.level.status=='ongoing' && data.gameInterest==null){
                  stateid.current=21;
                }
                else if(data.game!=null && data.level!=null && data.level.status=='completed' && data.gameInterest==null){
                  stateid.current=22;
                }

                else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='ready'){
                  stateid.current=3;
                }
                else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='ready'){
                  stateid.current=3;
                }
                /* else if(data.game!=null && data.level!=null && data.interest!=null && data.interest.status=='pair'){
                  stateid.current=4;
                } */
                    else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='pair' && data.level.status=='active' ){
                      stateid.current=41;
                    }
                    else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='pair' && data.level.status=='ongoing' ){
                      stateid.current=42;
                    }
                    else if(data.game!=null && data.level!=null && data.gameInterest!=null  && data.level.status=='completed' ){
                      stateid.current=43;
                    }
                    else if(data.game!=null && data.level!=null && data.gameInterest!=null && data.gameInterest.status=='ready' && data.level.status=='ongoing' ){
                      stateid.current=44;
                    }
                else if(data.game!=null &&  data.gameInterest!=null && (data.gameInterest.status=='timeout' || data.gameInterest.status=='expired')){
                  stateid.current=5;
                }
                else if(data.game!=null && data.level==null && data.gameInterest!=null && data.gameInterest.status=='ready'){
                  stateid.current=6;
                }
                console.log(`state status ${stateid.current}`)
                if (statetimeout.current) {
                  clearTimeout(statetimeout.current);
              }
                statetimeout.current= setTimeout(CheckState,10000);
                console.log(`on load2 timeout id:******** ${statetimeout.current}`)
          }else if(data.code==-10){
            setIsExpired(true);
          }
          else {    
            console.error(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error); 
        }
   }

   const CheckStart=async( )=>{
    
      
    var urls=`${Constants.rooturl}/game/checkstart/${levelstatus.current.id}`;
    try {
       // setLoading(true);
        const resp = await fetch(urls, { 
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            
          },
        });
        
        const data =parseInt( await resp.json());
      
       
        console.log(data);
        if (data == 0) {
           //
           console.log('e don happen');
           console.log(level)
          // setLevel({...level, status:'ongoing'});
          // levelstatus.current={...levelstatus.current,status:'ongoing'}
          Load(); //calling normal load.
        }
        else if(data==-10){
          setIsExpired(true);
        }else {
          //meaning its completed it should refresh
        
          timoutcall.current= setTimeout(onCallRefresh,10000);
        }
        /* else {    
          console.log('calling timer for checker');
          timoutcall.current= setTimeout(onCallRefresh,5000);

        } */
      } catch (error) {
      
        console.error(error); 
      }
 }

 const CheckState=async( )=>{
    
  if(statetimeout.current==null){
    console.log('it stop finally**************************')
    return;
  }
  console.log('here only if passed************************************');
      
  var urls=`${Constants.rooturl}/game/checkstate/${stateid.current}/${levelid.current}/${interestid.current}`;
  try {
     // setLoading(true);
      const resp = await fetch(urls, { 
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,  
        },
      });
      
      const data =parseInt( await resp.json());
    
     
      console.log(data);
      if (data == 0) {
        
        Load(); //calling normal load.
      }
      if (statetimeout.current) {
        clearTimeout(statetimeout.current);
    }
      statetimeout.current= setTimeout(CheckState,20000); //every 10 sec.
      console.log(`on CheckState timeout id:******** ${statetimeout.current}`)
    } catch (error) {
      if (statetimeout.current) {
        clearTimeout(statetimeout.current);
    }
      console.error(error); 
      statetimeout.current= setTimeout(CheckState,20000); //every 10 sec.
    }
}

const GameEndStatus=async(gid )=>{
    
      
  var urls=`${Constants.rooturl}/game/interestamount/${gid}`;
  try {
     // setLoading(true);
      const resp = await fetch(urls, { 
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,  
        },
      });
      
      const data =parseFloat( await resp.json());
    
      setWinAmount(data);

    } catch (error) {
    
      console.error(error); 
    }
}
  
  
   const onInterest=async()=>{
   
    setErrmsg('');
      setLoading(true);
     
      try {
        var v={levelId:level.id,gameId:game.id,customerId:profile.id,status:'Ready'}; //+1 include the present one about to call
        const resp = await fetch(`${Constants.rooturl}/game/showinterest`, {
          method: 'POST',
          body:JSON.stringify(v) ,
          headers: {
            'Content-Type': 'application/json, text/plain',
            'Authorization': token,
          },
        });
        
        const data = await resp.json();
        setLoading(false);
        console.log(data);
        if (data.code === 0) {
          //on success, show dialog and msg
             setInterest(data.gi);
         setIsInterested(false);
         setIsJoin(true);
         setPair(data.pair);
        } else{
          setErrmsg(data.status);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
       // setErr(Constants.serverErr);
      
       // onMove();
      }
   
   }

   const onRefresh=()=>{
    Load();
   }

const onStartGame=()=>{
  //store activepair
  console.log(`on beforeClear timeout id:******** ${statetimeout.current}`)
              clearTimeout(timoutcall.current);
              timoutcall.current=null;
              clearTimeout(statetimeout.current);
              statetimeout.current=null;
              console.log('clearing out the timer because page change');

              
    navigate('/customer/game/pair');
   }

  const onLogout =(e) =>{
    e.preventDefault(); 
   
    ActLogout();
  }

  const ActLogout=()=>{
    setProfile(null)
    setToken('');
    //save both token and profile on storage and set current date, so you can compare the date during refresh
   localStorage.removeItem("profile" )
   localStorage.removeItem("token");
   localStorage.removeItem("tokendate"); //this is keeping the date to try to relunch token or not
    navigate('/login');
  }

  
const onTapCopy =()=>{
  

  // if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard
    .writeText(`${Constants.baseurl}/leader/${profile?.sponsorCode}`)
    .then(() => {
     // setBttnText("COPIED");
      setICopy(true);
      setTimeout(function () {
        //setBttnText("COPY CODE");
        setICopy(false);
      }, 3000);
    })
    .catch((err) => {

      console.log(err.message);
    });
  }

  const onStartTime=()=>{ //for the up 
      
   
       
    count.current = count.current -1;
     setSeconds(count.current);
    
     console.log(`prev ${count.current} new `)
     if(count.current>=1){ //&& activepair.winnerId==null
      timoutcall.current=  setTimeout(onStartTime,1000);
     }
     
  }

  const onCallRefresh=()=>{ //for the up 
      
       
    count.current = count.current -1;
    setSeconds(count.current);
    
     console.log(`prev ${count.current} new ${stopSec} `)
     if(count.current>=stopSec ){ 
      //console.log('still on timer');
      timoutcall.current=  setTimeout(onCallRefresh,1000);
     }

    // console.log(`checking start if available, the status ${levelstatus.current.status}`);
      if((count.current<=stopSec &&  levelstatus.current.status=='active') || (interest!=null && interest.status=='ready')){
        
      CheckStart();
      
     } else{
      CheckState();
     }
     
  }

  return (
 
    <div>
       
    
    <GCustomerHeader active="dashboard" />
<div className='cusBody'> 
    <div className='title'>
      <div>
        <h3>Welcome {profile?.firstname} {profile?.lastname}!</h3>
        <h4>{profile?.phone}</h4>
      {profile?.sponsorCode!=null && <div className="place-copy">
                                <p>Sponsor Code <strong>{profile?.sponsorCode}</strong></p>
                               <div className="tap-copy" onClick={()=> onTapCopy()}>
                                    <p>{iCopy?'COPIED':'Tap to Leader Link'} </p>
                                    <FontAwesomeIcon icon={faCopy} />
                                </div>

                            </div>}  
      
      </div>
        
        <div onClick={onRefresh}><FontAwesomeIcon icon={faRefresh}  /> Refresh</div>
        {game!=null && level!=null && level.levelNo==1 && level.status=='active' && interest==null && <button className='btn_primary' onClick={()=>setIsInterested(true)}>Show Interest</button>} 
    </div>
    
{game!=null && level!=null && <div className='activegame'>
        <div>
            <div>Game status: {game?.status} </div>
            <div>
            <FontAwesomeIcon icon={faUser}  />
            <span>Current Level:  <strong>{level?.levelNo} </strong></span>
            </div>
        </div>
        <div className='time'>
            <span>{Constants.formatDate(level?.startTime)}</span>
            <span>{Constants.formatTime(level?.startTime)}</span>
        </div>
        <div>
           {level?.status=="ongoing" && interest?.status=='pair' && <div style={{textAlign:'right'}}><button className='btn_pry_red' onClick={onStartGame}>Start</button></div>} 
           {level?.status=="active" && seconds>=1 && <div style={{textAlign:'right'}}><button className='btn_secondary_red'>{Constants.timestr(seconds)}</button></div>}
            <div style={{textAlign:'right'}}>
            <FontAwesomeIcon icon={faUser}  />
            <span>Level {level.levelNo} Status: <strong> {level?.status}</strong></span>
            </div> 
        </div>
    </div>}
    
  {game!=null && level!=null && level?.status=="active"  && <div className='innerBox'>
        <div style={{flex:1}}><p className='blink_me2' style={{fontWeight:'bold', textAlign:'center', color:'red', fontSize:18}}>Game start by {Constants.formatTime2( level?.startTime)}. Use the Refresh Icon to reload your game at the time.</p></div>
       
    </div>}
    <div className='innerBox'>
        <div><FontAwesomeIcon icon={faGift}  /><span> Wallet</span></div>
        <div><span>{Constants.formatCurrency(balance)}</span></div>
    </div>
   {interest!=null && interest?.status !='pair' && <div  className='innerBox'>
       {interest?.status?.toLowerCase()=='ready' && <p className='waitpair'>{interest?.holding ?'Automatically qualify for next level':'Waiting for Pairing'} </p>} 
       {interest?.status?.toLowerCase()=='lost' && <p className='failedgame'>You lost. You will have to wait till game over before you can show interest again </p>} 
       {interest?.status?.toLowerCase()=='cashout' && <p className='failedgame'>You Cashed Out</p>} 
       {interest?.status?.toLowerCase()=='expired' && <p className='failedgame'> You did not come to play</p>} 
       {interest?.status?.toLowerCase()=='timeout' && <p className='failedgame'>Your game timeout! Your game was delayed and Admin terminate it because of time</p>} 
    </div>} 
        {pair!=null && <div className='innerBox'>
                
                <div className='pairs'>
                    <div className='pair'>
                        <h3>{pair?.pairA?.firstname}</h3>
                        <span>{pair?.pairA?.phone}</span>
                    </div>
                    <p>VS</p>
                    <div className='pair'>
                    <h3>{pair?.pairB?.firstname}</h3>
                    <span>{pair?.pairB?.phone}</span>
                    </div>
                </div>
            </div>}
    
    <div className='innerBox'>
        <Link to="/customer/fundwallet">
            <img src={img1} style={{height:70}} />
            <p>Fund Wallet</p>
            </Link>
        
          
            {profile?.sponsorCode!=null && <Link to={"/leader/downline"}>
            <img src={img6} style={{height:70}} />
            <p>Referals</p>
            </Link>} 
            <Link to={"/customer/account"}>
            <img src={img2} style={{height:70}} />
            <p>Account Statement</p>
            </Link>
            <Link  to="/customer/withdraw">
            <img src={img8} style={{height:70}} />
            <p>Withdraw from Wallet</p>
            </Link>
            <Link onClick={onLogout}>
            <img src={img9} style={{height:70}} />
            <p>Logout</p>
            </Link>

    </div>
    </div>
    <GDialog open={isInterested} text={`Note:  You have indicated your interest to Paticipate in the game,  
            one time fee of ${Constants.formatCurrency(game?.amount) } will be charge from your wallet  to be qualify for the game. Kindly fund your wallet with a  minimum of ${Constants.formatCurrency(game?.amount) }`}
             isAlert={false} onProceed={onInterest} onCancel={()=> {setIsInterested(false); setErrmsg('');}} errmsg={errmsg} loading={loading} />
     
     <GDialog open={isJoin} text={`You are now waiting to play the game at the schedule time. We hope to pair you soon or you already paired. Check your dashboard` } isAlert={true} onProceed={()=> setIsJoin(false)} />
     <GDialog open={gameEnded} text={`Game Ended. ${winAmount>0? `You win ${winAmount}`:'You Lost the game'}` } isAlert={true} onProceed={()=> {setGameEnded(false); setWinAmount(0)}} />
   <GExpired open={isExpired} type='customer' />      
    </div>

  )
}

export default Dashboard