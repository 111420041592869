import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate,useParams } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';
import GInput from '../components/gInput';
import Loader from '../components/loader';
import GExpired from '../components/gExpired';

function CustomerDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const { profile, adminToken, setAdminProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [customer, setCustomer]=useState({});
    const [payments, setPayments] = useState([])
    const [level,setLevel]  =useState(null);
    const [bal, setBal] =useState(0);
    const [fundamount,setFundamount]=useState(0);
    const [sponsorCode, setSponsorCode]=useState('')
    const [referalCode, setReferalCode]=useState('')
    const [uplines, setUplines]=useState([]);
    const [isExpired, setIsExpired] =useState(false);
    const [resetPass, setResetPass] =useState("");
    //load profile info.
  
    const [loading, setLoading] =useState(false);
 //{ code = 0, customers, payments };


    console.log(profile);
    useEffect(() => {
     
       Load();
       //LoadReports();
         }, [id]);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/admin/customers/${id}`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
               setCustomer(data.customer);
               setPayments(data.payments);
               setBal(data.bal);
               setUplines(data.under)
            }else if(data.code==-10){
              setIsExpired(true);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    
const onSendFund=async()=>{
    setLoading(true);
     try {
       var v={customerId:id, amount:fundamount};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
       const resp = await fetch(`${Constants.rooturl}/admin/fundwallet`, {
         method: 'POST',
         body:JSON.stringify(v) ,
         headers: {
           'Content-Type': 'application/json, text/plain',
           'Authorization': adminToken,
         },
       });
       
       const data = await resp.json();
       setLoading(false);
       console.log(data);
       if (data.code === 0) {
         ////just re load
         Load();
          
       } else if(data.code==-10){
        setIsExpired(true);
      }
     } catch (error) {
       setLoading(false);
       console.error(error);
      // setErr(Constants.serverErr);
     
      // onMove();
     }
}
const onPassReset=async()=>{
    setLoading(true);
     try {
       var v={username:customer.phone, password:resetPass};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
       const resp = await fetch(`${Constants.rooturl}/admin/passwordreset`, {
         method: 'POST',
         body:JSON.stringify(v) ,
         headers: {
           'Content-Type': 'application/json, text/plain',
           'Authorization': adminToken,
         },
       });
       
       const data = await resp.json();
       setLoading(false);
       console.log(data);
       if (data.code === 0) {
         ////just re load
         alert('Password reset completed');
         setResetPass('');
          
       } else if(data.code==-10){
        setIsExpired(true);
      }
     } catch (error) {
       setLoading(false);
       console.error(error);
      // setErr(Constants.serverErr);
     
      // onMove();
     }
}
const onSaveasleader=async()=>{
    setLoading(true);
   
    
     try {
       var v={customerId:id, sponsorCode:sponsorCode};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
       const resp = await fetch(`${Constants.rooturl}/admin/makeleader`, {
         method: 'POST',
         body:JSON.stringify(v) ,
         headers: {
           'Content-Type': 'application/json, text/plain',
           'Authorization': adminToken,
         },
       });
       
       const data = await resp.json();
       setLoading(false);
       console.log(data);
       if (data.code === 0) {
         ////just re load
        alert('Saved Successfully')
          
       }else if(data.code==-10){
        setIsExpired(true);
      } else{
        alert(data.status);
       }
     } catch (error) {
       setLoading(false);
       console.error(error);
      // setErr(Constants.serverErr);
     
      // onMove();
     }
}

const onSaveasDownline=async()=>{
  setLoading(true);
 
  
   try {
     var v={customerId:id, sponsorCode:referalCode};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
     const resp = await fetch(`${Constants.rooturl}/admin/makedownline`, {
       method: 'POST',
       body:JSON.stringify(v) ,
       headers: {
         'Content-Type': 'application/json, text/plain',
         'Authorization': adminToken,
       },
     });
     
     const data = await resp.json();
     setLoading(false);
     console.log(data);
     if (data.code === 0) {
       ////just re load
      alert('Saved Successfully')
        
     }else if(data.code==-10){
      setIsExpired(true);
    } else{
      alert(data.status);
     }
   } catch (error) {
     setLoading(false);
     console.error(error);
    // setErr(Constants.serverErr);
   
    // onMove();
   }
}
const onDetails =(g)=>{
  navigate(`/admin/games/${g.id}`)
     }

  return (
 
    <div>
    
    
<div className='topAdmin'>
            <GAdminSidebar active="customers" />
    <div className='contentside'>
    <GAdminHeader />
    <div  className='innerBox'>
        <div><span>First Name: </span><span>{customer.firstname}</span></div>
        <div><span>Last Name: </span><span>{customer.firstname}</span></div>
        <div><span>Phone Number: </span><span>{customer.phone}</span></div>
        <div><span>Email: </span><span>{customer.email}</span></div>
        <div><span>Referal code: </span><span>{customer.referalcode}</span></div>
        {customer.sponsorCode!=null &&
        <div><span>Leader Code: </span><span>{customer.sponsorCode}</span></div>
        }
    </div>
    <div className='innerBox'>
      <div>
        <h3>Fund Customer Account</h3>
      <GInput placeholder="Please enter the amount" value={fundamount}  onChange={(e)=>setFundamount(e.target.value)} /> 
        <button onClick={onSendFund} className='btn_primary'>Send Fund as Admin</button>
        {fundamount<0 && 
        <p style={{color:'red', fontWeight:'bold', textAlign:'center'}}>Note, using - sign in the number will deduct from the customer account</p>
        }
      </div>
      <div>
        <h3>Reset Password </h3>
      <GInput placeholder="Enter Password Reset" value={resetPass}  onChange={(e)=>setResetPass(e.target.value)} /> 
        <button onClick={onPassReset} className='btn_primary'>Save Password Reset</button>
      </div> 
    </div>
    {customer.sponsorCode==null &&  <div className='innerBox'>
      <div>
        <h3>Turn Customer to Leader</h3>
      <GInput placeholder="Unique Sponsor Code." value={sponsorCode}  onChange={(e)=>setSponsorCode(e.target.value)} /> 
        <button onClick={onSaveasleader} className='btn_primary'>Save as Leader</button>
      </div>
        
    </div>}

    {customer.referalcode==null &&  <div className='innerBox'>
      <div>
        <h3>Customer Leader (Referal Code)</h3>
      <GInput placeholder="Referal Code." value={referalCode}  onChange={(e)=>setReferalCode(e.target.value)} /> 
        <button onClick={onSaveasDownline} className='btn_primary'>Save Referal Code</button>
      </div>
        
    </div>}

    <div className='innerBox'>
     <div style={{flex:1}}>
      <h3>Payments: {bal}</h3>
      <table>
        <tr>
          <th>Pay Ref</th>
          <th>Amount</th>
          <th>Game </th>
          <th>Note</th>
          <th>Source</th>
          
        </tr>
        
        {payments?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.refId}</td>
                            <td>{cu.amount}</td>
                           <td>{cu.gameId}</td>
                           <td>{cu.note}</td>
                           
                            <td>{cu.source}</td>
                    </tr>
                  )})}
      </table>
     </div>
  {customer.sponsorCode!=null &&   
    <div style={{flex:1}}>
          <h3>Downlines (Customers on the Leader): {uplines.length}</h3>
          <table>
        <tr>
          <th>First name</th>
          <th>Last Name</th>
          <th>Phone </th>
          <th>Email</th>
         
          <th>Action</th>
        </tr>
        
        {uplines?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.firstname}</td>
                            <td>{cu.lastname}</td>
                           <td>{cu.phone}</td>
                           <td>{cu.email}</td>
                           
                           
                            <td><button className='btn_sec' onClick={()=>onDetails(cu)}>Details</button></td>
                    </tr>
                  )})}
      </table>
     </div> }
    </div>
    
  
    </div>

</div>
<Loader open={loading} />
<GExpired open={isExpired} type='admin' />
    </div>

  )
}

export default CustomerDetails