import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';


import '../css/dashboard.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift,
    faRefresh} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GInput from '../components/gInput';
import GSelect from '../components/gSelect';


function WithdrawWallet() {
    const navigate = useNavigate();
    const { profile, token, setProfile, setActivePair, setActiveLevel,setToken } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [user, setUser] =useState({});
    const [loading, setLoading] =useState(false);
    const [amount, setAmount] = useState(0);
    const [isExpired, setIsExpired] =useState(false);
    const [err, setErr] = useState("");
    const [account, setAccount] =useState({balance:0, isAccountVerified:false});
    const [banks, setBanks] = useState([]);


    console.log(profile);
    useEffect(() => {
     
      Load();
        
       
      
         }, []);
    

         const onCancel=()=>{
          navigate("/dashboard");
         }   


 const onWithdraw=async()=>{
     setLoading(true);
      try {
        var v={amount};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
        const resp = await fetch(`${Constants.rooturl}/game/withdraw`, {
          method: 'POST',
          body:JSON.stringify(v) ,
          headers: {
            'Content-Type': 'application/json, text/plain',
            'Authorization': token,
          },
        });
        
        const data = await resp.json();
        setLoading(false);
        console.log(data);
        if (data.code === 0) {
         alert("Withdraw Successful.");
           navigate(-1);
        } else if(data.code==-10){
         setIsExpired(true);
       }else{
            setErr(data.status)
       }
      } catch (error) {
        setLoading(false);
        console.error(error);
       // setErr(Constants.serverErr);
       setErr("Network Error");
       // onMove();
      }
 }
 const onAccount=async(e)=>{
  e.preventDefault(); 
  setLoading(true);
   try {
     var v={...account, fullName: `${account.firstname} ${account.lastname}`};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
     const resp = await fetch(`${Constants.rooturl}/game/account`, {
       method: 'POST',
       body:JSON.stringify(v) ,
       headers: {
         'Content-Type': 'application/json, text/plain',
         'Authorization': token,
       },
     });
     
     const data = await resp.json();
     setLoading(false);
     console.log(data);
     if (data.code === 0) {
      alert("Account Saved Successfully");
      setAccount({...account, isAccountVerified:true});
       
     } else if(data.code==-10){
      setIsExpired(true);
    }else{
         setErr(data.status)
    }
   } catch (error) {
     setLoading(false);
     console.error(error);
    // setErr(Constants.serverErr);
    setErr("Network Error");
    // onMove();
   }
}
const Load=async()=>{
      
        var urls=`${Constants.rooturl}/game/account`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
              setAccount(data.account);
              if(data.account.isAccountVerified==null){
                LoadBanks();
              }
            }else if(data.code==-10){
              setIsExpired(true);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
     const LoadBanks=async()=>{
      
      var urls=`${Constants.rooturl}/game/banks`;
      try {
          setLoading(true);
          const resp = await fetch(urls, { 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
              
            },
          });
          
          const data = await resp.json();
        
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
            setBanks(data.banks);
          }else if(data.code==-10){
            setIsExpired(true);
          }else {    
            console.error(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error); 
        }
   }

  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
    <GCustomerHeader active="dashboard" />
<div className='cusBody'> 
    <div className='title'>
      <div>
        <h3>Withdraw Wallet</h3>
      <h2>Bal: {account.balance}</h2>
      </div>
        
      
    
    </div>
    

  
   {account.isAccountVerified &&  <div className='innerBox'>
    <div>
    <p>Amount to Withdraw</p>
         <GInput label="Amount to Deposit" type='number' min={0} value={amount} onChange={(e)=>setAmount(e.target.value)}/>
<div className="success-box">
         <h2>Amount {Constants.formatCurrency(parseFloat(amount) +50) }</h2>
         <p>Transfer Charges (N50)</p>  
         <p style={{color:'red', fontWeight:'bold'}}>{err}</p>
           <button className='btn_primary'  type='button' onClick={onWithdraw}>Withdraw Now</button>  <button className='red' onClick={onCancel} type='button'>Cancel</button> 
       </div>     
         </div> 
         <div>
          <p>Account number: <strong>{account.accountNumber}</strong></p>
          <p>Bank: <strong>{account.bank}</strong></p>
          </div> 
    </div>}

    {!account.isAccountVerified &&  <div className='innerBox'>
          <form onSubmit={onAccount}>
         <p>Enter your bank details. [{account.firstname} {account.lastname}] </p>
         <p>Note. This account number used here will continue to be on record. Not reversable</p>
          <GInput placeholder="Account Number" type='text'  value={account.accountNumber} minlength={10} maxlength={10} onChange={(e)=>setAccount({...account,accountNumber: e.target.value})} />
          <p>Bank</p>
           <div className='inputtext'>
           
            <select  value={account.bankCode}  onChange={(e)=>setAccount({...account,bankCode: e.target.value, bank: e.target.options[e.target.selectedIndex].text})}>
              <option disabled>Select Bank</option>
              {banks.map((item, idx) =>{return (
                <option key={idx} value={item.code}>{item.name}</option>
              )})}
            </select>
           </div>
           <p style={{color:'red', fontWeight:'bold'}}>{err}</p>
           <button className='btn_primary'  type='submit' >Save Account Number</button> 
          </form>
    </div>}
   
    </div>
  
     
        
    </div>

  )
}

export default WithdrawWallet